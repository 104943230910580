import { ICar } from 'domain/Car/ICar';
import { AppConfig } from 'app-config';
import { Router } from 'aurelia-router';
import { LogManager, autoinject } from 'aurelia-framework';
import { Utils } from 'utils/helpers/utils';

export var log = LogManager.getLogger('app.components.car-tree');

@autoinject
export class CarTree {
  carCategories: { label: string; list: ICar[] }[];
  constructor(
    private router: Router,
    private appConfig: AppConfig, // used by HTML
  ) {}

  // #region ACTIONS

  attached() {
    this.categorizeCars();
  }

  carButtonClicked() {
    if (
      this.router.currentInstruction
        .getAllInstructions()
        .find(instruction => instruction.config.name == "carManufacturers")
    ) {
      this.router.navigateBack();
    } else {
      this.router.navigateToRoute("carManufacturers");
    }
  }

  loadCatalogueFor(car: ICar) {
    let query = car.licencePlate || car.vin;

    this.router.navigateToRoute("catalogueList", {
      vehicleId: car.id,
      query: Utils.encode(query),
    });
  }

  categorizeCars() {
    const now = Date.now();
    const oneDay = 86400000;
    const oneWeek = oneDay * 7;
    const startOfToday = new Date().setHours(0, 0, 0, 0);
    const startOfThisWeek = startOfToday - new Date().getDay() * oneDay;
    const startOfLastWeek = startOfThisWeek - oneWeek;

    let carsToday: ICar[];
    let carsThisWeek: ICar[];
    let carsLastWeek: ICar[];
    let carsOlder: ICar[];

    // Ensure every car has a valid timestamp, defaulting to 0 (treated as "Older")
    carsToday = this.appConfig.cars.filter(
      car => (car.timeAddedToSearch ?? 0) >= startOfToday,
    );

    carsThisWeek = this.appConfig.cars.filter(
      car =>
        (car.timeAddedToSearch ?? 0) >= startOfThisWeek &&
        (car.timeAddedToSearch ?? 0) < startOfToday,
    );

    carsLastWeek = this.appConfig.cars.filter(
      car =>
        (car.timeAddedToSearch ?? 0) >= startOfLastWeek &&
        (car.timeAddedToSearch ?? 0) < startOfThisWeek,
    );

    carsOlder = this.appConfig.cars.filter(
      car => (car.timeAddedToSearch ?? 0) < startOfLastWeek,
    );

    this.carCategories = [
      { label: "menu.car-search-history.today", list: carsToday },
      { label: "menu.car-search-history.this-week", list: carsThisWeek },
      { label: "menu.car-search-history.last-week", list: carsLastWeek },
      { label: "menu.car-search-history.older", list: carsOlder },
    ];
  }

  // #endregion
}
