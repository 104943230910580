import { I18N } from 'aurelia-i18n';
import { Utils } from 'utils/helpers/utils';
import { ICar } from 'domain/Car/ICar';
import { Router } from 'aurelia-router';
import { bindable, autoinject, LogManager } from 'aurelia-framework';
import { Collapse } from 'bootstrap';
import { CarHelper } from 'utils/helpers/car-helper';

export const log = LogManager.getLogger('app.components.car-menu');

@autoinject
export class CarMenuCustomElement {
  @bindable car: ICar;
  carDetailProperties = {};

  @bindable catalogueId: number;
  @bindable catalogueName: string;
  @bindable level: number;

  @bindable filterPlaceholder: string;
  @bindable filterValue: string;

  hasFocus = true;

  constructor(
    private router: Router,
    private i18n: I18N
  ) {
    this.carDetailProperties = {
      fullName: this.i18n.tr('pages.car.car-info.full-name'),
      licencePlate: this.i18n.tr('pages.car.car-info.licence-plate'),
      vin: this.i18n.tr('pages.car.car-info.vin'),
      capacityCcm: this.i18n.tr('pages.car.car-info.capacity-ccm'),
      powerKw: this.i18n.tr('pages.car.car-info.power-kw'),
      motorCodes: this.i18n.tr('pages.car.car-info.motor-codes'),
      constructionType: this.i18n.tr('pages.car.car-info.construction'),
      color: this.i18n.tr('pages.car.car-info.color'),
      fuel: this.i18n.tr('pages.car.car-info.fuel'),
      gearbox: this.i18n.tr('pages.car.car-info.gearbox'),
      registeredDate: this.i18n.tr('pages.car.car-info.registered'),
      originCountry: this.i18n.tr('pages.car.car-info.origin-country'),
      nextInspectionDate: this.i18n.tr('pages.car.car-info.next-inspection'),
      drivingAxles: this.i18n.tr('pages.car.car-info.driving-axles')
    };
  }

  attached() {
    const carDetails = document.getElementById('car-details');

    if (this.car.vin && this.level == 3 && !this.filterValue && carDetails)
      new Collapse(carDetails).toggle();
  }

  // #region ACTIONS

  openManufacturers() {
    this.router.navigateToRoute('carManufacturers');
  }

  openModels() {
    this.router.navigateToRoute('carModels', {
      id: this.car.model.manufacturer.id,
      name: Utils.encode(this.car.model.manufacturer.name)
    });
  }

  openVehicles() {
    this.router.navigateToRoute('carVehicles', {
      manuId: this.car.model.manufacturer.id,
      modelId: this.car.model.id
    });
  }

  openCatalogues() {
    const parameters: any = {
      vehicleId: this.car.id
    };
    const query = this.car.licencePlate || this.car.vin;
    if (query)
      parameters.query = Utils.encode(query, true);
    if (this.filterValue)
      parameters.filterText = Utils.encode(this.filterValue, true);
    if (this.catalogueId)
      parameters.catalogueId = Utils.encode(this.catalogueId, true);

    this.router.navigateToRoute('catalogueList', parameters);
  }

  openTires(tire: string) {
    const [width, height, diameter] = CarHelper.decodeTireString(tire);

    this.router.navigateToRoute('categoryProducts', {
      categoryId: 10,
      page: 1,
      filters: Utils.encode(JSON.stringify({
        width: [width],
        height: [height],
        diameter: [diameter]
      }), true)
    });
  }

  isValidTireString(tire: string) {
    return CarHelper.isValidTireString(tire);
  }

  goBack() {
    switch (this.level) {
      case 1:
        this.openManufacturers();
        break;
      case 2:
        this.openModels();
        break;
      case 3:
        this.openVehicles();
        break;
      case 4:
        this.openCatalogues();
        break;
    }
  }

  // #endregion
}
